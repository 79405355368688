import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { graphql, PageProps } from "gatsby"
import SEO from "../components/seo"
import BrandBox from "../components/BrandBox"
// @ts-ignore
import FimesGallery from "../components/SwiperSlider/FimesGallery"
import { FluidObject } from "gatsby-image"

interface IPartnerdProps extends PageProps<{
    fimesLogo: {
      childImageSharp: {
        fluid: FluidObject
      }
    }
  },
  {
    slug: string
    lang: string
    templateName: string
  }> {
  className?: string
}

const content = `
<p>Always using the finest materials Fimes beds exude elegance yet meet very high standards of comfort.
<br>
Every inch of a wardrobe or closet designed by Fimes is a carefully orchestrated symphony of style, functionality and premiere quality.
<br>
Fimes approaches every area of the bedroom space with unprecedented attention to detail, maximizing its potential, 
but offering extraordinary design solutions backed up by years of experience and tradition. 
<br>
Credibility, quality and innovation are our priorities on our quest to meet our customer’s needs. 
Fimes furniture in your home is a tangible sign that beauty is here to stay.
</p>
`

const PartnerItemPage: React.FC<IPartnerdProps> = ({location:{pathname:path} ,className, data, pageContext }) => {

  const localText = data.partner.locales.find(l => l.iso === pageContext.lang)

  return (
    <Layout lang={pageContext.lang} page={path}>
      <SEO
        title={localText.metaTitle}
        description={localText.metaDescription}
        keywords={localText.metaKeywords}
      />
      <div className={className}>
        <section className="section brand-page">
          <BrandBox
            gallery={<FimesGallery galleryImages={data.partner.gallery.galleryImages} />}
            title={localText.title}
            content={localText.description}
            logo={data.partner.logoImageFile.childImageSharp.fluid}
            link={data.partner.url}
          />
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
query PartnerPageItem($uid: String!) {
    partner(uid: {eq: $uid}) {
    locales {
      title
      description
      content
      iso
      metaDescription
      metaKeywords
      metaTitle
    }
    url
    logoImageFile {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
            gatsbyImageData(quality: 90) 
        }
      }
    logo {
      uri
    }
    gallery {
      galleryImages {
        position
        galleryImage {
            publicURL
            uid
            name
            childImageSharp {
                gatsbyImageData(aspectRatio: 1.7, layout: FULL_WIDTH, transformOptions: {cropFocus: CENTER}, quality: 90)  
            }
        }
      }
    }
  }
}
`

// export const query = graphql`
//   {
//     fimesLogo: file(relativePath: {eq: "ci_fimeslogo4x.png"}) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `

export default styled(PartnerItemPage)`
  top: 0;
  padding-top: 25px;
  position:relative;
  max-width: 1200px;
  margin: 50px auto auto auto;
  @media screen and (min-width: 1051px) {
    margin-top: 80px;
  }
  padding-bottom: 100px;
`
